:root {
  --village-selector-height: 80px;
  --village-color-blue: #14cff0;
}

.hiw-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    margin-bottom:5rem;
    width: 70%;
    display: flex;
    flex-direction: column;
}

.hiw-container h1{
    font-family: Fredoka One;
    font-size: 50px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-bottom: 3rem;  
}

.hiw-container h2{
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: #616161;
    margin-bottom: 100px;
}

.hiw-price-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 500px;
    margin-left: auto;
    margin-right: auto;
}


.switch-field {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: end;
  }

.switch-field-element {
  position: relative;
  height: 80px;

}

.switch-field-element:not(:first-of-type) {
  margin-left: -60px;
}
  
  
  .switch-field input {
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0;
  }
  
  .switch-field label {
    display: inline-block;
    position: relative;
    padding: 0px 80px;
    background-color:#f4f7f8;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    transition: all .1s;
    color: #dfdfdf;
    user-select: none;
    border-radius: 40px;
    font-size: 20px;
    font-weight: bold;
    font-family: Source Sans Pro;
  }

  .switch-field label::after {
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: 8px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
  }
  
  .switch-field input:checked + label {
    background-color: var(--village-color-blue);
    border-color: var(--village-color-blue);
    color: white;
    z-index: 1;
    box-shadow: 0px 15px 20px -2px rgba(black, .1);
  }
  
  .switch-field label:first-of-type {
    padding-right: 80px;
  }

  .switch-field label:first-of-type::before {
    right: 0;
    top: 0;
  }

  .switch-field label:first-of-type::after {
    right: 12px;
    top: 9px;
  }
  
  .switch-field label:last-of-type {
    padding-left: 80px;
  }

  .switch-field label:last-of-type::before {
    left: 0;
    top: 0;
  }

  .switch-field label:last-of-type::after {
    left: 12px;
    top: 9px;
  }

  input[type="radio"]:checked{
    visibility:hidden;
    -webkit-appearance: none;
    -moz-appearance: none;
 }

/* tablet, ipad  version (change font-size here if needed)*/
@media (min-width: 768px) and (max-width: 1024px){

    .hiw-container {
        margin-left: auto;
        margin-right: auto;
        margin-bottom:5rem;
        width: 50%;
    }

}

/* mobile version (change font-size here if needed)*/
@media (max-width: 600px) {

    .hiw-container {
        margin-left: auto;
        margin-right: auto;
        margin-bottom:5rem;
        width: 70%;
    }

}