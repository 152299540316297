.class-card-tile  {
    width: 240px;
    height: 360px;
    background-color: #666666;
    position: relative;
    display: inline-block;
    border-radius: 20px;
    object-fit: fill;
}

.class-card-tile h5 {
    width: 90%;
    position: absolute;
    margin: auto;
    text-align: center;
    top:25%; bottom:0; left:0; right:0;
    font-family: Fredoka One;
    font-size: 18px; 
    font-weight: normal;
    line-height: 1.6;
    color: #ffffff  
}

.class-card-tile p {
    width: 90%;
    position: absolute;
    margin: auto;
    text-align: center;
    top:45%; bottom:0; left:0; right:0;
    font-family: Source Sans Pro;
    font-size: 14px; 
    line-height: 1.6;
    color: #ffffff 
}

.class-card-btn {
    position: absolute;
    margin: auto;
    text-align: center;
    top:80%; bottom:0; left:0; right:0;
}