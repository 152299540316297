
.footer-navbar{
    background-color: white;
    width: 90%;
    margin-top: 2%;
    margin-bottom: 2%;
    font-family: Fredoka One;
    font-size: 18px;
    margin: auto;
}

.footer-logo {
    width: 214px;
    height: 28px;
    margin-right: 40px;/*theme.spacing(3)*/
}
.footer-container{
    display: flex;
    box-shadow: none;
    background-color: #ffffff;
    /*margin-top: 24; /*theme.spacing(3),*/
    bottom: 0;
    width: 90%;
   /* height: 2.5rem;*/
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer-menu {
    display: flex;
    position: relative;
}

.footer-subscribe {
    display: flex;
    border-radius: 21px;
    margin-right: 16; /*theme.spacing(2),*/
    margin-left: 24; /*theme.spacing(3),*/
    width: 467px;
    height: 70px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-follow-container {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: 10px;/*theme.spacing(2),*/
    margin-top: 20px;
    min-width: 300px;
}

.footer-follow-text {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: #ff7255;
    font-family: Source Sans Pro;
    min-width: 75px;
    margin-left: 40px;
    margin-right: 5px;
}

.footer-follow-icon {
    width: 18px;
    height: 18px;
    margin-right: 2px;
    margin-left: 2px;
    color: #ff7255;
}

.footer-copyright-text {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #ff7255;
    font-family: Source Sans Pro;
}

.footer-subscribe-form-control {
    width: 470px;
    height: 40px;
    border-radius: 20px;
    font-family: Source Sans Pro;
    text-align: left;
    color: #cdcece;
    background-color: #f4f7f8;
    border-style: none;
}

