.footer-follow-container-mobile {
    text-align: center;
    align-items: center;
}

.footer-follow-container-mobile p {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: #ff7255;
    font-family: Source Sans Pro;
}

.footer-follow-icon-mobile {
    width: 18px;
    height: 18px;
    margin-right: 2px;
    margin-left: 10px;
    padding-top: 5px;
    color: #ff7255;
} 

.footer-menu-mobile {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    width: 100%
}

.footer-subscribe-form-control-mobile {
    width: 90%;
    height: 30px;
    margin-bottom: 0px;
    border-radius: 20px;
    font-family: Source Sans Pro;
    font-size: 12px;
    text-align: left;
    color: #cdcece;
    background-color: #f4f7f8;
    border-style: none;
}

.footer-copyright-text-mobile {
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    align-items: center;
    text-align: center;
    color: #ff7255;
    font-family: Source Sans Pro;;
}




.footer-copyright-text-mobile {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-right: 20px;
    color: #ff7255;
    font-family: Source Sans Pro;
}



