.price-card-container {
    width: 300px;
    height: 420px;
    background-color: white;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    border-radius: 30px;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s;
}

.price-card-container:hover {
    width: 340px;
    height: 460px;
    background-color: #14cff0;
    z-index: 1;

}

.price-card-text-container h4 {
    font-family: Source Sans Pro;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: left;
    color: #14cff0;
    margin-bottom: 10px;
}

.price-card-container:hover h4{
    color: #09778b;
}

.price-card-text-container h5 {
    font-family: Source Sans Pro;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
}

.price-card-container:hover h5{
    color: white;
}

.price-card-text-container h6 {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #14cff0;
}

.price-card-container:hover h6{
    color: #ffffff;
}

.price-card-text-container p {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: normal;
  text-align: left;
  color: #616161;
  max-height: 150px;  
  min-height: 150px;
  max-width: 200px;
  min-width: 200px;
  margin-top: 35px;
  margin-bottom: 5px;

}

.price-card-container:hover .price-card-text-container p {
    color: white;
}

.price-card-container:hover .price-card-blue-button {
    border-radius: 21px;
    border-style: solid; 
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #14cff0;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #ffffff; 
    border-color: #ffffff;
    font-family: Source Sans Pro;
}

.price-card-blue-button {
    border-radius: 21px;
    border-style: solid; 
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #ffffffff;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #14cff0; 
    border-color: #14cff0;
    font-family: Source Sans Pro;
    min-width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}


.price-card-blue-button:focus {
    outline: none;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
}

.price-card-blue-button:active {
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    transform: translateY(4px);
}

.price-card-container:hover .price-card-blue-button {
    border-radius: 21px;
    border-style: solid; 
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #14cff0;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #ffffff; 
    border-color: #ffffff;
    font-family: Source Sans Pro;
}

.price-card-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 180px;
    height: 300px;
    max-height: 300px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.price-card-section-bottom {
    bottom: 0px;
}