.login-modal-mobile {
    width: 100%;
    height: 200px;
}

.login-modal-mobile h1 {
    font-family: Fredoka One;
    font-size: 36px; 
    font-weight: normal;
    line-height: 1;
    color: #333333;
}

.login-modal-mobile a {
    font-family: Fredoka One;
    font-size: 14px; 
    font-weight: normal;
    line-height: 1;
    color: #616161;
}

.login-modal-mobile-form {
    width: 280px;
    height: 40px;
    margin-top:10px;
    border-radius: 20px;
    background-color: #f4f7f8;
    font-family: Source Sans Pro;
    text-align: left;
}

.button-link-like-mobile {

}