 
 #ck-button {
     margin:3px;
     background-color: transparent;
     overflow:auto;
     float:left;

 }

 #ck-button span {
    box-shadow: 0px 8px 18px 0 rgba(0, 0, 0, 0.14);
    border-radius: 21px;
    padding: 5px 10px;
    text-align: center;
    display: inline-block;
    color: #ff7255;
    user-select: none;
    min-width: 80px;
    width: fit-content;
  }
 
 #ck-button label {
     float:left;
     padding: 1px 1px 0px 1px;
     font-family:Source Sans Pro;
     font-size: 18px;
     font-weight: bold;
 }
 

 
 #ck-button label input {
     text-align: center;
     position:absolute;
     top:-20px;
 }
 
 #ck-button input:checked + span {
     background-color:#ff7255;
     color:#fff;
     font-weight: bold;
 }