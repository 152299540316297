.home-slider {
    width: 70%;
    margin-left: 20%;
    margin-right: 10%;
}

.home-slider h3{
    font-size: 24px;
    color: white;
    text-align: left;
    font-family: Fredoka One;
}

.home-slider p{
    font-size: 16px;
    color: white;
    font-weight: normal;
    text-align: left;
    font-family: Source Sans Pro;
}

.slider-item {
    width: 100%;
    height: 400px;
    border-radius: 20px;
    object-fit: cover;
}

.slider-item-content {
    margin-bottom: 40px;
    border-radius: 20px;
    background: linear-gradient(#cdcece50,#cdceceb0);
    padding: 10px 10px 10px 10px;
}

.slider-button {
    text-align: left;
    margin-top: 50px;;
}


/* mobile version (change font-size here if needed)*/
@media (max-width: 600px) {
    .home-slider {
        width: 70%;
        margin-left: 10%;
        margin-right: 10%;
    }

    .slider-button {
        text-align: left;
        margin-top: 20px;;
    }

    .home-slider h3 {
        font-size: 16px;
    }

    .home-slider p{
        font-size: 12px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .slider-item-content {
        margin-bottom: 20px;
        border-radius: 20px;
        background-color: #ff725590;
        padding: 10px 10px 10px 10px;
    }

}