
 .empty-tile {
    width: 240px;
    height: 360px;
    border-radius: 20px;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    align-items: center;
    text-align: center;
    justify-content: center ;
    display: flex;
    flex-direction: column;
}


.empty-tile h1 {
    font-family: Fredoka One;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.44px;
    text-align: center;
    color: #cacaca;
    padding-left: 15px;
    padding-right: 15px;
}

.button-empty-tile {
    border-radius: 21px; 
    background-color: #f4f7f8; 
    border-color: #cacaca;
    font-family: Source Sans Pro;
    font-size: 16px;
    text-align: center;
    color: #cacaca;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
}