.discover-container {
    margin-top: 5rem;
    margin-bottom:5rem;
    margin-left: auto;
    margin-right: auto;
    width: 70%
}

.discover-container h1 {
    font-family: Fredoka One;
    font-size: 50px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
}

.discover-container h2 {
    font-family: Source Sans Pro;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: -0.6px;
    text-align: left;
    color: #616161;
    margin-top: 5%;
    margin-bottom: 2%;
}