.gallery-image { visibility: hidden; } /* prevent a flash of giant image before the image is wrapped by jQuery */

.gallery-image-wrap { 
      /* whatever the dimensions you want the "cropped" image to be */
      height: 156px;
      width: 156px;
      overflow: hidden; 
 }

 .gallery-image-wrap img.square {
      visibility: visible;
      width: 100%;  
 }

 .gallery-image-wrap img.portrait {
      visibility: visible;
      width: 100%;
      height: auto;
 }

 .gallery-image-wrap img.landscape {
      visibility: visible;
      height: 100%;
      width: auto;
 }