
body,html{
    height :100%;
}
  
.app-container{
    display :flex;
    flex-direction:column;
    min-height:100vh;
    position: relative;
}

.app-header{
    background-color:white;
    padding:10px;
  }

.app-header-menu{
    background-color:white;
    padding:10px;
    width:100vw;
}

.app-footer{
    text-align:center;
    bottom: 0;
    }
    
.app-footer-menu{
    padding:10px;
    background-color:white;
    width:100vw;
}