.modal-register {
  display: none;
  position: center;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.25);
}

.form-container {
  width: 60%;
  min-height: 80px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
  border-radius: 20px;
  
}

.row-container {
  width: 100%;
  min-height: 150px;
}

.arrow-form {
  color: #616161
}

.arrow-form:hover {
  color: #ff7255
}

.form-arrow-elt-prev {
  margin: 10px
}

.form-arrow-elt-next {
  margin-top: 8%;
}

.form-element {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.form-element-input {
  outline: 0px; 
  border: 0px;
  border-bottom: 4px solid #ff7255;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 550px;
  height: 42px;
  font-size: 28px;
  background-color: transparent;
}

.form-element-input:focus {
    outline-width: 0;
}

.form-element-input-area {
  outline: 0px; 
  border: 0px;
  border-bottom: 4px solid #ff7255;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 550px;
  height: 50px;
  font-size: 14px;
  background-color: transparent;
}

.form-element-input-area:focus {
    outline-width: 0;
}

.form-element-input-file {
  outline: 0px; 
  border: 0px;
  border-bottom: 4px solid #ff7255;
  margin: 0;
  position: absolute;
  top: 65%;
  -ms-transform: translateY(-65%);
  transform: translateY(-65%);
  width: 550px;
  height: 50px;
  font-size: 14px;
  background-color: transparent;
}

.form-element-input-file-button {
  outline: 0px; 
  border: 0px;
  border-bottom: 4px solid #ff7255;
  margin: 0;
  position: absolute;
  top: 60%;
  -ms-transform: translateY(-60%);
  transform: translateY(-60%);
  width: 550px;
  height: 50px;
  font-size: 14px;
  background-color: transparent;
  display: flex;
  orientation: line;
}

.container-tags{
  display: flex;
  flex-direction: column;
}

.checkbox-style {
  border-width: 2px;
  border-color: #ff7255;
}


.option-checkbox {
  border-radius: 21px; 
  background-color: ffffff; 
  border-color: #ffffff;
  font-family: Source Sans Pro;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #ff7255;
}

.label-txt {
    font-family: Source Sans Pro;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: -0.6px;
    text-align: left;
    color: #333333;
    margin-bottom: 50px;
}

.form-control-village {
    font-family: Source Sans Pro;
    font-size: 16px; 
    line-height: 1.6;
    text-align: left;
    color: #616161;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    background-color: #ffffff;
    border-bottom: 3px solid #ff7255;
    border-top: 0px none;
    border-left: 0px none;
    border-right: 0px none;
}

.check-box-village {
  border-radius: 21px; 
  background-color: white; 
  border-color: #ff7255;
  border-style: solid;
  border-width: 2px;
  font-family: Source Sans Pro;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #ff7255;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 4px;
}

.check-box-village:checked {
  border-radius: 21px; 
  background-color: #ff7255; 
  border-color: #ff7255;
  border-style: solid;
  border-width: 2px;
  font-family: Source Sans Pro;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
}

.progress-bar {
  background-color: #ff7255 !important; 
}


.form-class-cat-group {
  width: 600px;
  height: 600px;
}

.line-schedule-container {
  margin-top: 20px;
  margin-bottom: 30px;

  display: inline-flex;
}

.line-schedule-container h1 {
  font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.77;
  letter-spacing: -0.6px;
  text-align: left;
  color: #333333;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 8px;
}


.form-element-input-schedule {
  outline: 0px; 
  border: 0px;
  border-bottom: 4px solid #ff7255;
  margin: 0;
  font-size: 18px;
  background-color: transparent;
  font-family: Source Sans Pro;
}

