.header-village {
    background-color: white;
    width: 100%;
    height: 80px;
    margin: 2% auto;
  }
  
.header-search-container {
    max-width: 470px;
    min-width: 470px;
    height: 40px;
    border-radius: 20px;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: auto;
 }

.header-search-form {
    max-width: 430px;
    min-width: 430px;
    height: 40px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 0px none;
    background-color: #f4f7f8;
    font-family: Source Sans Pro;
    text-align: left;
    color: #cecece;
    border: none;
}
  
  .header-search-prepend {
    width: 40px;
    height: 40px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: 0px none;
    background-color: #f4f7f8;
  }
  
  .header-search-img {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 18px;
    height: 18px;
    margin-top: 30%;
  }
  
  .header-avatar-pic {
    width: 45px;
    height: 45px;
    border-radius: 100px;
  }
  
  .header-menu-item {
    font-family: Fredoka One;
    font-size: 18px;
    margin-right: 25px;
    color: #dfdfdf;
  }
  
  .header-menu-item:hover {
    color: #ff7255;
    border-bottom-style: none;
    border-bottom: 0px;
  }
  
  .header-divider {
    background-color: #ff7255;
  }
  
  .drop-down-menu {
    border-radius: 50px;
    box-shadow: "0px 8px 18px 0 rgba(0,0,0,0.14)";
  }
  .dropdown-toggle > div {
    display: inline-block;
  }
  .navbar-nav {
    align-items: center;
    margin-left: auto;
  }
  
  .header-drop-down-name {
    font-size: 20px;
    font-family: Source Sans Pro;
    color: #333333;
    font-weight: bold;
  }
  
  .nav-item {
    font-size: 20px;
    font-family: Source Sans Pro;
    color: #616161;
    font-weight: normal;
  }
  
  .nav-item:hover {
    color: #ff7255;
  }
  
  @media (max-width: 1300px) {
    .header-search-container {
      max-width: 360px;
      min-width: 360px;
      flex-wrap: nowrap;
      margin-left: auto;
    }
    .navbar-expand-lg .navbar-collapse {
      flex-grow: inherit;
      margin-left: auto;
    }

    .header-search-form {
      max-width: 320px;
      min-width: 320px;
    }


  }
  
  @media (max-width: 990px) {
    .header-search-container {
      margin-right: auto;
      max-width: 400px;
      min-width: 400px;
    }

    .header-search-form {
      max-width: 360px;
      min-width: 360px;
    }
  }
  @media (max-width: 800px) {
    .header-search-container {
      order: 1;
      margin-top: 20px;
      max-width: 400px;
      min-width: 400px;
    }

    .header-search-form {
      max-width: 360px;
      min-width: 360px;
    }
  }
  
  .username-style {
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 3px solid #ff7255;
    text-align: center;
    margin: 2px 11px 10px 11px;
    padding-bottom: 2px;
}