.home-carousel {
    width: 70%;
    margin-left: 20%;
    margin-right: 10%;
}

/* mobile version (change font-size here if needed)*/
@media (max-width: 600px) {
    .home-carousel {
        width: 70%;
        margin-left: 10%;
        margin-right: 10%;
    }
}