
.home-container {
    margin-top: 2rem;
    margin-bottom:5rem;
    margin-left: 0;
    margin-right: 0;
}

.discover-style {
    display: flex;
    align-items: top;
    margin-left: 15%;
}

.home-full-section {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom:4rem;
    width: 100%;
    height: 600px;
    position: relative;
    background-image: url(./lander.png);
    background-size: cover;
    border-radius: 21px;
}

.home-think-text-section {
    margin-top: 10%;
    margin-left: 1.5%;
    width: 25rem;
}

.home-think-text-section h1 {
    font-family: Fredoka One;
    font-size: 2,5rem;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    color: white;
}

.home-think-text-section p {
    width: 200px;
    font-family: Source Sans Pro;
    font-size: 22px;
    font-weight: normal;
    text-align: left;
    color: white;
}

.home-section {
    margin-left: auto;
    margin-right: auto;
    margin-bottom:3rem;
    width: 60%;
}

.home-section h1{
    margin-bottom:2rem;
    font-family: Fredoka One;
    font-size: 2,5rem;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    color: #333333;
}

.home-section p{
    margin-bottom:2rem;
    font-family: Source Sans Pro;
    font-size: 1rem;
    font-stretch: normal;
    font-weight: normal;
    line-height: 1,6;
    text-align: left;
    color: #616161;
}

.home-banner {
    margin-top: 10%;
    width: 54px;
    height: 82px;
}

.add-space {
    margin-top: 5rem;
}

/* tablet, ipad  version (change font-size here if needed)*/
@media (min-width: 600px) and (max-width: 1024px){

    .home-think-text-section h1 {
        font-size: 34px;
    }
    .home-think-text-section p {
        font-size: 22px;
    }
}

/* mobile version (change font-size here if needed)*/
@media (max-width: 600px) {
    .home-section {
        margin-left: auto;
        margin-right: auto;
        margin-bottom:3rem;
        width: 80%;
    }

    .home-section h1{
        font-size: 16px;
    }

    .home-section p{
        font-size: 12px;
    }

    .home-full-section {
        width: 100%;    
        height: 200px;
    }

    .home-banner {
        margin-top: 10%;
        width: 28px;
        height: 42px;
    }

    .home-think-text-section, .home-think-text-section h1, .home-think-text-section p{
        width: calc(100% - 30%); /* subtract the left:20% of .text-tile in desktop-version  and set full width */
    }
    .home-think-text-section h1 {
        font-size: 18px;
    }
    .home-think-text-section p {
        font-size: 14px;
    }



}
