.review-content {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;

}

.review-profile-info {
    display: inline-flex;
    margin-right: 15px;
}

.review-details-header {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
}

.review-avatar-pic {
    width: 42px;
    height: 42px;
    border-radius: 50px;
}

.review-profile-info-txt {
    margin-left: 10px;
}

.review-profile-info-txt {
    margin-left: 10px;
}

.review-profile-info-txt h5{
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
}

.review-profile-info-txt h6{
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #cfcfcf;
}

.review-profile-info p{
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #616161;
}
