.buycredits-container {
    margin-top: 7rem;
    margin-bottom:5rem;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    align-items: left;
}

.buycredits-container h1 {
    font-family: Fredoka One;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.58;
    letter-spacing: -0.8px;
    text-align: left;
    color: #333333;
}

.buycredits-container p {
    margin-left: 1rem;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #616161;
}

.buycredits-text-message {
    margin-top: 5rem;
    display: inline-flex;
}