.settings-container {
    margin-top: 4rem;
    margin-bottom:5rem;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    align-items: left;
}

.settings-container h1 {
    font-family: Fredoka One;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.58;
    letter-spacing: -0.8px;
    text-align: left;
    color: #333333;
}

.settings-container p {
    margin-left: 1rem;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #616161;
}

.settings-text-message {
    margin-top: 5rem;
    display: inline-flex;
}


.setting-user-image {
    height: 156px;
    width: 156px;
    border-radius: 50%;
    margin-bottom: 30px;
}

.settings-tab-item-left{
    font-family: Source Sans Pro;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: -0.6px;
    text-align: right;
    color: #333333;
    margin-top: 20px;
    margin-bottom: 20px;
}

.settings-tab-item-left:hover{
    font-family: Source Sans Pro;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: -0.6px;
    text-align: right;
    color: #ff7255;
    margin-top: 20px;
    margin-bottom: 20px;
    outline: none;
}

.settings-tab-item-left:focus{
    font-family: Source Sans Pro;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: -0.6px;
    text-align: right;
    color: #ff7255;
    margin-top: 20px;
    margin-bottom: 20px;    
    outline: none;

}

.settings-tab-content {
    background-color: white;
    margin-left: 20px;
    border-radius: 20px;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
}

.settings-tab-right{
    margin: 40px 40px 40px 40px;
}

.settings-tab-right h1 {
    font-family: Fredoka One;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.58;
    letter-spacing: -0.8px;
    text-align: left;
    color: #333333;
    margin-bottom: 20px;
    margin-left: 0px;
    padding-top: 20px;
}

.settings-tab-right h2 {
    font-family: Source Sans Pro;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    margin-left: 0px;
    color: #616161;
}

.settings-tab-right h3 {
    font-family: Source Sans Pro;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    margin-left: 0px;
    color: #333333;
}
.settings-tab-right p {
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #616161;
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-top: 10px;
}


.settings-tab-right a{
    background-color: transparent;
    border: none;
    color: #14cff0;
    box-shadow: 0 0 0 0 ;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    margin-right: 10px;  
    margin-top:10px;  
    margin-bottom: 0px;
}

.settings-tab-right a:hover {
    text-decoration: underline;
    text-decoration-color: #14cff0;
    color: #14cff0;
}

.settings-tab-right a:focus {
    outline: none;
    box-shadow: none;
  }

.blue-btn-no-space {
    border-radius: 21px; 
    background-color: #14cff0;
    border-color: #14cff0;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #ffffffff;
    object-fit: contain;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 10px;
}

.blue-btn-no-space:focus {
    outline: none;
    box-shadow: none;
  }

  .form-label-settings {
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.51;
    letter-spacing: normal;
    text-align: left;
    color: #cecece;
    margin-top: 10px;
    margin-left: 20px;
}

.form-control-settings {
    object-fit: contain;
    border-radius: 20px;
    background-color: #f4f7f8;
}

.form-form-settings {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
}

