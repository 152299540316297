.default-white-button {
    border-radius: 21px;
    border-style: solid; 
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #ff7255;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #ffffff; 
    border-color: #ffffff;
    font-family: Source Sans Pro;
}

.default-white-button:focus {
    outline: none;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
  }

.default-white-button:active {
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    transform: translateY(4px);
}

/* tablet, ipad  version (change font-size here if needed)*/
@media (min-width: 600px) and (max-width: 1024px){
}

/* mobile version (change font-size here if needed)*/
@media (max-width: 600px) {
    .default-white-button {
        font-size: 12px;
        padding-top: 2px;
        padding-bottom: 3px;
        padding-left: 8px;
        padding-right: 8px;
    }
}