.discover-section-container{
    margin-top: 5rem;
    margin-bottom:5rem;
    margin-left: auto;
    margin-right: auto;
    width: 70% 
}

.discover-section-item {
    margin: 10px;
}

.discover-section-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    grid-auto-rows: 'minMax(100px, auto';
}