.create-container {
    margin-top: 3rem;
    margin-bottom: 5rem; 
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.create-container h1 {
    text-align: center;
    height: 51px;
    font-family: Fredoka One;
    font-size: 50px;
    color: #333333;
}

.create-section {
    width:100%;
}

.create-icon {
    width:47px;
    height:47px ;
}

.create-style-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.create-tile {
    display: inline-block;
    left: 12px;
    top: 80px;
    text-align: left;
    width: 200px;
    height: 250px;
    background-color: #ffffff;
}

.create-tile h2 {
    font-family: Source Sans Pro;
    font-weight: bold;
    font-size: 30px;
    line-height: 0.7;
    color: #333333;
    letter-spacing: -0.6px;
    margin-left: 15px;

}

.create-tile p {
    font-family: Source Sans Pro;
    font-size: 20px;
    line-height: 1.6;
    color: #616161;
    width: 280px;
    height: 85px; 
}

.create-button {
    position: relative;
    left: 0px;
    top: 50px;
}

/* tablet, ipad  version (change font-size here if needed)*/
@media (min-width: 768px) and (max-width: 1024px){
    .create-container h1 {
        font-size: 34px;
    }
    .create-tile h2 {
        font-size: 22px;
    }

    .create-tile p {
        font-size: 14px;
    }
}

/* mobile version (change font-size here if needed)*/
@media (max-width: 600px) {
    .create-container h1 {
        font-size: 34px;
    }
    .create-tile h2 {
        font-size: 22px;
    }

    .create-tile p {
        font-size: 14px;
    }
}