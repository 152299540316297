
.header-wall {
    background-color: white;
    width: 100%;
    height: 80px;
    margin: 2% auto;
  }

.village-wall-banner {
    width: 65px;
    height: 99px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    display: block;
}
.wall-body {
    width: 80%;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;

}

.wall-white-button {
    border-radius: 21px;
    border-style: solid; 
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #ff7255;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    padding-top: 2px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #ffffff; 
    border-color: #ffffff;
    font-family: Source Sans Pro;
    margin-left: 10px;
    margin-right: 10px;
    height: 40px;
}

.wall-white-button:focus {
    outline: none;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
  }

.wall-white-button:active {
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    transform: translateY(4px);
}

.form-control-wall {
    max-width: 430px;
    min-width: 430px;
    height: 40px;
    border-radius: 20px;
    border-left: 0px none;
    background-color: #f4f7f8;
    font-family: Source Sans Pro;
    text-align: left;
    color: #cecece;
    border: none;
    padding-left: 20px;
}

.wall-blue-button {
    border-radius: 21px;
    border-style: solid; 
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #ffffffff;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    padding-top: 2px;
    padding-bottom: 3px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #14cff0; 
    border-color: #14cff0;
    font-family: Source Sans Pro;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.wall-blue-button:focus {
    outline: none;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
  }

.wall-blue-button:active {
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    transform: translateY(4px);
}

.form-wall{
    min-width: 250px;
    max-width: 250px;
    height: 40px;
    border-radius: 21px;
    background-color: #f4f7f8;
    font-family: Source Sans Pro;
    font-size: 14px;
    text-align: left;
    color: #cecece;
    padding-left: 20px;
    border: none;
    margin-left: 10px;
}

.form-wall-password{
    min-width: 100px;
    max-width: 100px;
}

.wall-inline-form {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: auto;
}   

.teacher-form {
    display: flex;
    flex-direction: column;
}

.teacher-form-inline {
    display: flex;
    flex-direction: row;
}
.wall-body h1 {
    font-family: Fredoka One;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
}

.wall-body p{
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: #616161;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.wall-body-section-center {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 100px;
}

.wall-body-section-center-add {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 200px;
}

.img-card {
    position: relative;
    object-fit: contain;
    border-radius: 20px;
}

.blue-circle-icon {
    background-color: #14cff0;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    margin-left: auto;
    margin-right: 0px;
    position: relative;
    top: 20px; 
    right: -20px;
    z-index: 1;
}

.icon-tag {
    height: 15px;
    width: auto;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);

}

.img-card-small {
    border-radius: 20px;
}

.wall-body-section-left {
    width: 60%;
    margin-left: 0px;
    margin-right: auto;
    margin-bottom: 40px;
}

.wall-image-full {
    width: 80%;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}

.wall-banner {
    width: 100%;
}

.wall-copyright {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #ff7255;
    font-family: Source Sans Pro;
}