
 .create-onboarding {
     margin-top: 8%;
     width: 43%;
     margin-left: auto;
     margin-right: auto;
}


.create-onboarding h1 {
    font-family: Fredoka One;
    font-size: 50px;
    text-align: left;
    color: #333333;
}


.create-onboarding p {
    font-family: Source Sans Pro;
    font-size: 20px; 
    line-height: 1.6;
    text-align: left;
    color: #616161;
}