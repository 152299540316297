.class-details-container {
    margin-top: 2.5rem;
    margin-bottom:5rem;

}

.class-details-header {
    width: 60% ;
    height: 250px;
    background-size: 100% 100% no-repeat;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    border-radius: 20px;
    box-sizing: border-box;
    background-color: #14cff0;
}

.class-details-image {
    width: 100%;
    height: auto;
    max-height: 300px;
    aspect-ratio: 1;
    border-radius: 21px;
}

.class-details-content {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.class-details-content h1 {
    font-family: Fredoka One;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.58;
    letter-spacing: -0.8px;
    text-align: left;
    color: #333333;
}

.class-details-content h2 {
    font-family: Source Sans Pro;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #14cff0;
    margin-top: 3%
}

.class-details-toolbar {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}


.class-details-class-content{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.class-details-class-content h2{
    font-family: Source Sans Pro;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: -0.6px;
    text-align: left;
    color: #333333;
    margin-top: 8%;
    margin-bottom: 3%;
}

.avatar-pic-content {
    width: 60px;
    height: 60px;
}

.avatar-pic-profile {
    width: 54px;
    height: 54px;
    border-radius: 50px;
}

.class-teacher-info {
    display: inline-flex;
    margin-right: 15px;
}

.class-teacher-info-txt{
    margin-left: 10px;
}

.class-teacher-info-txt h5{
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #616161;
    margin: 0;
    padding: 0;

}

.class-teacher-info-txt p{
  font-family: Source Sans Pro;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #616161;
  margin: 0;
  padding: 0;

}

.review-summary {
    display: inline-flex;
}

.review-summary p {
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ff7255;
    vertical-align: text-bottom;
}

.review-summary-progress {
    color: #ff7255;
}

.map-container {
    height: 20%;
    width: 50%;
}


.class-details-gallery {
    margin-top:  3%;
}

.class-details-container-onError {
    margin-top: 2.5rem;
    margin-bottom:5rem;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.class-details-container-onError h1{
    font-family: Fredoka One;
    font-size: 50px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
}

.class-details-container-onError h2{
    font-family: Source Sans Pro;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: -0.6px;
    text-align: left;
    color: #616161;
    margin-top: 5%;
    margin-bottom: 2%;
}


@media (max-width: 600px) {
    .class-details-container-onError h1{
        font-family: Fredoka One;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #333333;
    }
    
    .class-details-container-onError h2{
        font-family: Source Sans Pro;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.6px;
        text-align: left;
        color: #616161;
        margin-top: 5%;
        margin-bottom: 2%;
    }
}