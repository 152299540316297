.profile-container {
        margin-top: 3rem;
        margin-bottom:5rem;

}

.profile-tab-space {
    margin-top: 15px;
    margin-bottom: 15px;

}

.profile-header {
    position: relative;
    height: 300px;
    width: 70%;
    background-size: 100% 100% no-repeat;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 145px;
    border-radius: 20px;
    box-sizing: border-box;

}


.profile-header-image-user {
    position: absolute;
    height: 156px;
    width: 156px;
    border: 1px solid white;
    border-radius: 50%;
    bottom: 0;
    left: 50px;
    transform: translateY(50%);
    box-shadow: inherit;
    margin-left: 2.7%;
}

.profile-content {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.profile-gallery {
    margin-top:  3%;
}

.profile-tabs {
    width: 60%;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
}

#ReactGridGallery{
    display:flex;
  }

.profile-content h1{
    font-family: Fredoka One;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.58;
    letter-spacing: -0.8px;
    text-align: left;
    color: #333333;
}

.profile-content h3{
    font-family: Source Sans Pro;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #616161;
}

.profile-content h2{
    font-family: Source Sans Pro;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: -0.6px;
    text-align: left;
    color: #333333;
    margin-top: 3rem;
}


.profile-content p {
    font-family: Source Sans Pro;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #616161;
}

.profile-tabitem {
    font-family: Source Sans Pro;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: -0.6px;
    text-align: left;
    color: #d9d9d9;
}

.profile-tabitem:active {
    font-family: Source Sans Pro;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: -0.6px;
    text-align: left;
    color: #ff7255;
}

.btn-edit-profile{
    border-radius: 21px;
    background-color: #14cff0; 
    border-color: #14cff0;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #ffffffff;
    box-shadow: 0px 8px 18px 0 rgba(0,0,0,0.14);
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ff7255;
    background-color: #fff;
    border-color: none;
}

.nav-tabs {
    border-bottom: none;
}

.nav-tabs .nav-link:hover{
    color: #616161
}